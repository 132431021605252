<template>
  <CustomDialog
    :title="title"
    @button1="cancel"
    @button2="get_cropped_image"
    :open.sync="dialog"
    :max-width="boundary.width * 1.5"
    :button2-text="mainButton"
    :main-btn-disabled="!file_uploaded"
  >
    <template v-slot:content>
      <v-card flat class="pt-2">
        <Loader :loading="loading" />
        <v-card-text v-if="file_uploaded">
          <CropImage
            ref="croppie"
            :image="image64"
            :options="croppie.options"
            :result="croppie.result"
          />
        </v-card-text>
        <v-sheet
          v-else
          color="whitesmoke"
          height="350"
          class="d-flex"
          style="border: 3px dotted #223a6b"
        >
          <v-file-input
            ref="fileuploader"
            class="d-none"
            @change="file_added"
            accept="image/*"
            label="File input"
          ></v-file-input>
          <v-btn
            @click="openUploader"
            color="primary"
            large
            class="text-none align-self-center mx-auto"
          >
            <v-icon left large>mdi-upload</v-icon> Upload Image
          </v-btn>
        </v-sheet>
      </v-card>
    </template>
  </CustomDialog>
</template>
<script>
//Components
import Loader from '@/common/BaseComponents/Loader.vue'
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import CropImage from '@/common/CropImage.vue'
import request from '@/services/axios_instance'

export default {
  name: 'ImageCropper',
  components: {
    Loader,
    CustomDialog,
    CropImage
  },
  props: {
    value: { type: Boolean, default: false },
    title: { type: String, default: 'Upload Image' },
    type: { type: String, default: 'square' },
    width: { type: [Number, String], default: 200 },
    height: { type: [Number, String], default: 200 },
    extraData: [Object, Array],
    mainButton: { type: String, default: 'Save' },
    allowResize: { type: Boolean, default: false },
    folder: { type: String, default: 'icons' },
    valueFormat: { type: String, default: 'attachment' } //or file
  },
  data: () => ({
    file_uploaded: false,
    image64: null,
    loading: false,
    dialog: false,
    submitting: false
  }),
  watch: {
    value: {
      handler(val) {
        this.dialog = val
        if (val) {
          this.submitting = false
          this.loading = false
          this.file_uploaded = false
        }
      },
      immediate: true
    },
    dialog(val) {
      this.$emit('input', val)
    }
  },
  computed: {
    boundary() {
      return {
        width: Number(this.width) + 100,
        height: Number(this.height) + 100
      }
    },
    croppie() {
      return {
        options: {
          viewport: {
            width: Number(this.width),
            height: Number(this.height),
            type: this.type
          },
          showZoomer: true,
          boundary: this.boundary,
          enableResize: this.allowResize,
          enableOrientation: true
        },
        result: 'blob'
      }
    }
  },

  methods: {
    file_added(file) {
      const reader = new FileReader()
      reader.onload = () => {
        this.image64 = reader.result
        this.file_uploaded = true
      }
      reader.readAsDataURL(file)
    },
    get_cropped_image() {
      this.submitting = true
      this.$refs.croppie.get_result().then((data) => {
        this.valueFormat === 'file'
          ? this.emitFile(data)
          : this.emitAttachment(data)
      })
    },
    emitFile(blob) {
      this.$emit(
        'file',
        new File([blob], 'file.png', {
          type: 'image/png',
          lastModified: new Date().getTime()
        })
      )
      this.$refs.croppie.clear_component()
      Object.assign(this.$data, this.$options.data.apply(this))
    },
    emitAttachment(blob) {
      let file = new File([blob], 'file.png', {
        type: 'image/png',
        lastModified: new Date().getTime()
      })
      var formData = new FormData()
      formData.append('file', file)
      request
        .post(`api2/attachments/dropzone`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(({ data }) => {
          this.$emit('file', data)
        })
        .finally(() => {
          this.$refs.croppie.clear_component()
          Object.assign(this.$data, this.$options.data.apply(this))
        })
    },
    cancel() {
      this.dialog = false
    },
    openUploader() {
      this.$refs.fileuploader.$refs.input.click()
    }
  }
}
</script>
<style lang="scss" scoped>
.content {
  display: flex;
  justify-content: center;
}
</style>
